import React from 'react';
import Header from './Header';
import Counter from './Counter';
import Clock from './Clock';

import './styles.css';

export default function App() {
  let showClock = false;
  return (
    <>
      <Header title="Adel" color="blue" />
      <Header title="Hend" color="green" />
      {showClock ? <Clock /> : ''}
      <Counter initial={6} />
      <Counter initial={30} />
    </>
  );
}
