import React, { useState } from 'react';

export default function Counter({ initial }) {
  let [count, setCount] = useState(initial);

  const increament = e => {
    setCount(count + 1);
  };

  const decreament = e => {
    if (count > 0) setCount(count - 1);
  };

  return (
    <div>
      <h3>{count}</h3>
      <button onClick={increament}>+</button>
      <button onClick={decreament}>-</button> {count}
    </div>
  );
}
